import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  checkboxField,
  dropZoneField,
  carouselField,
  docListField,
  colorPicker,
  textBlock,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import {
  required,
  minLength,
  maxLength,
  hasDate,
  hasTime,
} from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'
import { readonly } from 'vue'

export default {
  id: uuidv4(),
  name: 'Основные',
  type: 'FormDefault',
  path: 'add',
  alias: 'doljnost_x5',
  active: false,
  detail: true,
  lists: [],
  fields: [
    stringField({
      label: 'Название',
      name: 'name',
      placeholder: '',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      readonly: {
        value: false,
        condition: [
          {
            funcCondition: (ctx) => {
              return !!ctx.environment.readonlyAll
            },
            type: true,
          },
        ],
      },
      validations: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255),
      },
      bootstrapClass: [''],
    }),
    selectField({
      label: 'type_json',
      name: 'type_json',
      subtype: 'multiple',
      placeholder: '',
      class: ['displayNone'],
      value: [1],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      position: {
        cols: 12,
        sm: 12,
      },
      readonly: true,
      bootstrapClass: [''],
    }),
  ],
  actions: [
    stringAction({
      text: 'Закрыть',
      type: 'submit',
      color: 'textDefault',
      name: 'closePopup',
      action: 'closePopup',
      skipValidation: true,
    }),
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      color: 'primary',
      module: 'form/create',
      url: 'create/doljnost',
      name: 'createForm',
      action: 'createForm',
      handlingResponse: {
        1: {
          text: 'Успешно',
          color: 'success',
        },
        2: {
          text: 'Ошибка на стороне сервера',
          color: 'error',
        },
        3: {
          text: 'Не хватает данных',
          color: 'warning',
        },
        4: {
          text: 'Нет доступа',
          color: 'error',
        },
        5: {
          text: 'Данные не проходят валидацию',
          color: 'error',
        },
        6: {
          text: 'Должность с таким названием уже существует',
          color: 'warning',
        },
      },
      isHide: {
        value: false,
        type: 'every',
        condition: [
          {
            field: 'mode',
            target: 'environment',
            value: ['edit'],
            type: true,
          },
        ],
      },
    }),
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      module: 'form/putForm',
      url: 'update/doljnost',
      name: 'saveForm',
      action: 'saveForm',
      color: 'primary',
      handlingResponse: {
        1: {
          text: 'Успешно',
          color: 'success',
        },
        2: {
          text: 'Ошибка на стороне сервера',
          color: 'error',
        },
        3: {
          text: 'Не хватает данных',
          color: 'warning',
        },
        4: {
          text: 'Нет доступа',
          color: 'error',
        },
        5: {
          text: 'Данные не проходят валидацию',
          color: 'error',
        },
        6: {
          text: 'Должность с таким названием уже существует',
          color: 'warning',
        },
      },
      isHide: {
        value: false,
        type: 'every',
        condition: [
          {
            field: 'mode',
            target: 'environment',
            value: ['add'],
            type: true,
          },
        ],
      },
    }),
  ],
}
